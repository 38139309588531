// https://sourcegraph.in.trifacta.net/phab.trifacta.com/diffusion/TF/trifacta/-/tags?query=jenkins%2Frelease%2F
module.exports = {
  ee: {
    "9.7": "release/9.7/push/ee/2203516",
    "9.2": "TD-release-9.2/ee/188",
    "8.7": "TD-release-8.7/ee/304",
    "8.2": "TD-release-8.2/ee/118",
    "7.6": "TD-release-7.6/ee/320",
    "7.1": "TD-release-7.1/ee/537"
  },
  // note that there should only be one version of the cloud editions
  "dataprep-enterprise-cloud22": {
    current: "release/10.1/push/dataprep-enterprise-cloud22/2986503"
  },
  "dataprep-enterprise-cloud": {
    current: "release/10.1/push/dataprep-enterprise-cloud/2986503"
  },
  "dataprep-professional": {
    current: "release/10.1/push/dataprep-professional/2986503"
  },
  "dataprep-premium": {
    current: "release/10.1/push/dataprep-premium/2986503"
  },
  "dataprep-standard": {
    current: "release/10.1/push/dataprep-standard/2986503"
  },
  "dataprep-starter": {
    current: "release/10.1/push/dataprep-starter/2986503"
  },
  "saas-enterprise-cloud22": {
    current: "release/10.1/push/saas-enterprise-cloud22/2925054"
  },
  "saas-enterprise-cloud": {
    current: "release/10.1/push/saas-enterprise-cloud/2925054"
  },
  "saas-professional": {
    current: "release/10.1/push/saas-professional/2925054"
  },
  "saas-premium": {
    current: "release/10.1/push/saas-premium/2925054"
  },
  "saas-standard": {
    current: "release/10.1/push/saas-standard/2925054"
  },
  "saas-starter": {
    current: "release/10.1/push/saas-starter/2925054"
  }
};
