const confluenceDocLink = "https://docs.trifacta.com/display";
const config = require("./config");
const latestEEVersion = Object.keys(config.ee).sort((a, b) => +b - +a)[0];

function createOption({ version, link, text }) {
  const option = document.createElement("option");
  option.innerText = text || version;
  option.dataset.link = link;
  option.id = `v${version}`;
  return option;
}

const versionSelector = document.getElementById("ee-version-selector");

[...versionSelector.querySelectorAll("option")].forEach(option => option.remove());

const eeVersions = Object.keys(config.ee)
  .sort((a, b) => +b - +a)
  .map((version, index) => ({
    link: `/ee/${version}/index.html`,
    version,
    text: index === 0 ? `v${version} (latest)` : version
  }));

eeVersions
  .concat([
    {
      version: "6.8",
      link: `${confluenceDocLink}/r068/API+Reference?os_username=tr9gm2kks8ef068&os_password=kN2F8Baa43Plme`
    },
    {
      version: "6.4",
      link: `${confluenceDocLink}/r064/API+Reference?os_username=tr9gh3l1f064&os_password=kNYfVIukLLoasw`
    },
    {
      version: "6.0",
      link: `${confluenceDocLink}/r060/API+Reference?os_username=tr9f2k12ool060&os_password=kRTn545vxoe2323`
    }
  ])
  .forEach(({ version, link, text }) => {
    versionSelector.appendChild(createOption({ version, link, text }));
  });

function changeVersion(version) {
  const eeVersionLink = document.getElementById("ee-version-link");
  const option = document.getElementById(`v${version}`);
  eeVersionLink.href = option ? option.dataset.link : `/ee/${latestEEVersion}/index.html`;
}

versionSelector.addEventListener("change", event => {
  changeVersion(event.target.value);
});

// handle browser back button
setTimeout(() => changeVersion(versionSelector.value), 0);
